<template>
    <div class="d-flex">
        <iframe :src="previewUrl" width="814px" height="700px" frameborder="0" style="margin: auto; border: 1px solid lightgrey"></iframe>
    </div>
</template>

<script>
import { APP_SERVER_HOST } from '@/servers'

export default {
    computed: {
        previewUrl () {
            return `${APP_SERVER_HOST}/#/login?redirect_to=%2Flesson-plan-preview%2F${this.$route.params.item_id}`
        }
    }
}
</script>